<template>
  <app-chip
    class="bg-accent-100 text-accent-950"
    icon="ph:calendar-blank"
    :size="size"
  >
    {{ formattedDate }}
    {{ formattedTime ? `⋅ ${formattedTime}` : "" }}
  </app-chip>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    session: Pick<DatabaseTable<"course_sessions">, "start_date" | "end_date">;
    time?: boolean;
    size?: "small";
  }>(),
  { size: undefined },
);

const formattedDate = useI18nDate(properties.session.start_date ?? "", {
  day: "2-digit",
  month: "short",
});

const formattedTime = computed(() => {
  if (!properties.time) return;

  return `${
    useI18nDate(properties.session.start_date!, { timeStyle: "short" }).value
  } - ${
    useI18nDate(properties.session.end_date!, { timeStyle: "short" }).value
  }`;
});
</script>
